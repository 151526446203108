async function send(api, requestBody) {
	let baseUrl = "https://us-central1-bilinguo.cloudfunctions.net";

	let url = `${baseUrl}/${api}`;
	let response = await fetch(url, {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		method: "post",
		body: JSON.stringify(requestBody),
	});

	console.log(`sent ${JSON.stringify(requestBody)}`);
	return await response.json();
}
module.exports.send = send;