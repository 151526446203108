<template>
  <div class="bg-primary-100 text-center">
    <navbar />
    <div class="md:w-2/3 lg:w-1/2 mx-4 md:mx-auto pt-6">
      <p class="text-2xl my-8">your library</p>
      <p class="pb-4">here are bilingual texts you have created in the past</p>
      <p class="my-2 mb-6">
        want to see texts shared by other users? click
        <button class="underline" @click="$router.push('/public')">here</button>
      </p>
      <div v-if="loading">
        <loading-icon class="my-3 mx-auto mb-10" />
      </div>
      <div v-else class="flex flex-col">
        <text-preview v-for="text in texts" :key="text.id" :text="text" />
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./Navbar.vue";
import TextPreview from "./TextPreview.vue";
export default {
  components: { Navbar, TextPreview },
  data: function () {
    return {
      texts: undefined,
    };
  },
  metaInfo: {
    title: "Library",
  },
  computed: {
    loading: function () {
      return this.texts == undefined;
    },
  },
  methods: {
    sendRequest: async function (api, requestBody) {
      let baseUrl = "https://us-central1-bilinguo.cloudfunctions.net";

      let url = `${baseUrl}/${api}`;
      let response = await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(requestBody),
      });

      console.log(`sent ${JSON.stringify(requestBody)}`);
      let responseAsJson = await response.json();
      return responseAsJson;
    },
  },
  mounted: async function () {
    let response = await this.sendRequest("my-texts", {
      sessionId: this.$store.state.sessionId,
    });
    this.texts = response.list;
    this.texts.reverse();
  },
};
</script>