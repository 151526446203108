<template>
  <div class="flex flex-col bg-white text-center">
    <navbar :isFixed="true" />
    <div class="mt-16">
      <div v-if="showWordTranslation" class="">
        <popup>
          <div
            v-if="waitForTranslation"
            class="flex-shrink p-4 bg-white rounded-lg m-auto"
          >
            <loading-icon />
          </div>
          <div
            v-else
            class="flex-shrink my-auto mx-4 md:mx-auto p-6 w-full md:w-1/2 bg-white text-gray-700 rounded-lg"
          >
            <p class="text-2xl">{{ wordTranslation.before }}</p>
            <hr class="my-4" />
            <p class="text-3xl">{{ wordTranslation.after }}</p>

            <button
              @click="addToWordList()"
              class="border rounded-lg w-full p-2 mt-6 transform hover:bg-black hover:text-white duration-200"
            >
              add to word list
            </button>

            <button
              @click="closeTranslation()"
              class="border rounded-lg w-full p-2 mt-2 transform hover:bg-black hover:text-white duration-200"
            >
              close
              <!--<font-awesome-icon
                icon="times"
                size="lg"
                class="rounded-full transform hover:scale-125 duration-200"
              />-->
            </button>
          </div>
        </popup>
      </div>
      <div v-if="loading" class="p-8">
        <loading-icon />
      </div>
      <div v-else>
        <div
          class="mt-6 text-primary flex md:flex-row flex-col md:space-x-6 space-y-2 md:space-y-0 items-center justify-center mx-4"
        >
          <div class="flex-shrink">
            <simple-button @click="download()" color="primary">
              download
            </simple-button>
          </div>
          <div class="flex-shrink">
            <simple-button @click="downloadAsHtml()" color="white">
              download as html
            </simple-button>
          </div>
          <div v-if="text.isAuthor" class="flex">
            <div class="flex-shrink" v-if="!showTitleInput">
              <simple-button @click="showTitleInput = true" color="white">
                change title
              </simple-button>
            </div>
            <div v-else>
              <p class="p-2">input title here and click confirm</p>
              <input
                type="text"
                v-model="newTitle"
                class="rounded border p-2 mr-4"
              /><simple-button @click="changeTitle" class="">
                confirm
              </simple-button>
            </div>
          </div>
        </div>
        <p
          v-if="text.title"
          class="pt-10 text-2xl md:text-4xl text-primary mt-4"
        >
          {{ text.title }}
        </p>
        <div class="mt-8 text-gray-500" v-if="canTranslateWord">
          <p>click a word to get translation (need to sign in)</p>
          <p class="p-2">translation use credits (1 credit per character)</p>
        </div>
        <p v-else class="mt-8 text-gray-500">
          on-click translation not supported for this language
        </p>
        <div v-if="hasLicense">
          <p class="m-10 rounded border p-4">{{ licensePrefix }}</p>
        </div>
        <div class="text-black m-6 bg-white rounded text-left p-4">
          <component
            :is="bilingualTemplate"
            v-if="canTranslateWord"
          ></component>
          <div v-html="bilingual" v-else></div>
        </div>
        <simple-button
          color="primary"
          @click="$router.push('/feedback')"
          class="mt-4 mb-20"
          >send feedback</simple-button
        >
        <div v-if="hasLicense">
          <pre class="m-10 rounded border p-4">{{ licenseSuffix }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bilingualHelper from "../bilingualText";
import Navbar from "./Navbar.vue";
import request from "../request";
import dl from "../download";
import scrollPosition from "../scrollPosition";

export default {
  components: { Navbar },
  metaInfo: function () {
    return {
      title: `${this.metaTitle}`,
    };
  },
  data: function () {
    return {
      translateOnClick: true,
      loading: true,
      newTitle: "",
      showTitleInput: false,
      text: undefined,
      timer: undefined,
      showWordTranslation: false,
      waitForTranslation: false,
      wordTranslation: {
        before: "",
        after: "",
      },
      licensePrefix: `This eBook is for the use of anyone anywhere at no cost and with
almost no restrictions whatsoever.  You may copy it, give it away or
re-use it under the terms of the Project Gutenberg License included
with this eBook or online at www.gutenberg.org`,
      licenseSuffix: ``,
    };
  },
  watch: {},
  computed: {
    hasLicense: function () {
      if (this.text) return this.text.license != undefined;
      return false;
    },
    licensePath: function () {
      if (this.text) return `/free-samples/license/${this.text.license}.txt`;
      return "";
    },
    metaTitle: function () {
      if (this.title) {
        return `${this.title} - Bilingual / Parallel Text`;
      }
      return "Bilingual / Parallel Text";
    },
    title: function () {
      if (this.text) return this.text.title;
      return "";
    },
    scrollKey: function () {
      return `text-scroll-pos-${this.$route.params.textId}`;
    },
    bilingualTemplate: function () {
      //creating dynamic component
      //doing this to have ability to translate words with click
      if (!this.text) return "";
      let html = bilingualHelper.toHtmlWithTranslation(this.bilingualRaw);
      return {
        template: `<div>${html}</div>`,
        methods: {
          translateWord: async function (word) {
            this.$parent.translateWord(word);
          },
        },
      };
    },
    bilingual: function () {
      if (!this.text) return "";
      //return bilingualHelper.toHtmlWithTranslation(this.bilingualRaw);
      return bilingualHelper.toHtml(this.bilingualRaw); //this is faster version but no translating word function
    },
    bilingualRaw: function () {
      if (!this.text) return "";
      return bilingualHelper.create(this.text.before, this.text.after);
    },
    canTranslateWord: function () {
      console.log("checking if can translate...");
      if (!this.$store.getters.isSignedIn) return false;
      if (this.text == undefined) return false;
      console.log("lang:" + this.text.language);
      if (this.text.language == "ja") return false; //no support for japanese cause no spaces
      if (this.text.language == "zh") return false; //chinese dont have spaces
      console.log("can translate!");
      return true;
    },
  },
  methods: {
    download: function () {
      dl.asText(this.bilingualRaw);
    },
    downloadAsHtml: function () {
      dl.asHtml(this.bilingualRaw);
    },
    changeTitle: async function () {
      let textId = Number(this.$route.params.textId);
      this.loading = true;
      let response = await request.send("text-title", {
        sessionId: this.$store.state.sessionId,
        textId: textId,
        title: this.newTitle,
      });
      this.text.title = response.title;
      this.loading = false;
      this.showTitleInput = false;
    },
    translateWord: async function (word) {
      if (!this.canTranslateWord) return; //block translating
      this.showWordTranslation = true;
      this.waitForTranslation = true;
      console.log(word);
      let response = await request.send("translate-word", {
        input: word,
        sessionId: this.$store.state.sessionId,
        targetLanguage: "en",
      });
      this.waitForTranslation = false;
      this.wordTranslation.before = response.before;
      this.wordTranslation.after = response.after;
      this.$store.commit("setUser", response.user);
      console.log(response);
    },
    closeTranslation: function () {
      this.showWordTranslation = false;
    },
    addToWordList: async function () {
      this.closeTranslation();
      await request.send("add-word", {
        before: this.wordTranslation.before,
        after: this.wordTranslation.after,
        sessionId: this.$store.state.sessionId,
      });
    },
    loadLicense: async function () {
      if (!this.hasLicense) return;
      console.log("loading license");
      let license = await fetch(this.licensePath);
      let licenseContent = await license.text();
      this.licenseSuffix = licenseContent;
    },
  },
  mounted: async function () {
    let textIdString = this.$route.params.textId;
    let response = await request.send("text", {
      sessionId: this.$store.state.sessionId,
      textId: Number(textIdString),
    });
    this.text = response.text;
    console.log("can click" + this.canTranslateWord);
    this.newTitle = this.text.title;
    this.loading = false;

    await this.loadLicense();

    await new Promise((r) => setTimeout(r, 100));

    scrollPosition.load(this.scrollKey);
    this.timer = setInterval(() => {
      if (!this.loading && this.bilingual != "") {
        scrollPosition.save(this.scrollKey);
      }
    }, 5000);
  },
  beforeDestroy: function () {
    clearInterval(this.timer);
  },
};
</script>