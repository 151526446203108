<template>
  <div>
    <navbar />
    <div class="flex text-white text-center lg:h-screen">
      <div class="flex-1 p-8 lg:p-14 lg:h-screen">
        <div class="h-full flex flex-col justify-center lg:p-8">
          <p class="text-4xl pb-8">thank you!</p>
          <p class="my-5 text-xl">
            thank you for buying credits and supporting the app!
          </p>

          <p class="pt-3">
            any questions? contact me at
            <a class="underline" href="mailto:jaroslawweber@gmail.com"
              >jaroslawweber@gmail.com</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data: function () {
    return {};
  },
  methods: {},
  mounted: function () {
    this.$gtag.event("payment-successful", { method: "Google" });
  },
  props: {
    msg: String,
  },
};
</script>
