<template>
  <div class="flex flex-col">
    <Navbar />
    <div class="flex-1 flex text-white text-left flex-col lg:flex-row pt-14">
      <div class="flex-1 p-8 lg:p-14">
        <div class="h-full flex flex-col justify-center">
          <div class="w-full my-3 text-center text-3xl">
            What do you want to read?
          </div>
          <p class="pt-6 text-center">
            find novel, news or blog post <br />in the language you want to
            learn <br />and paste it here
          </p>
          <p class="pt-8 text-center">not sure what to do?</p>
          <div class="flex justify-center">
            <simple-button
              @click="$router.push('/tutorial')"
              color="white"
              class="mt-4 w-1/2"
              >see tutorial video</simple-button
            >
          </div>
        </div>
      </div>
      <div class="flex-1 px-8 lg:p-14 lg:m-8">
        <div class="h-full flex flex-col justify-center text-center">
          <div class="bg-white rounded text-black">
            <textarea
              class="mt-1 p-3 w-full"
              rows="10"
              v-model="textBeforeTranslation"
              placeholder="paste text in foreign language here"
              @input="splitLinesDone = false"
            ></textarea>
          </div>

          <font-awesome-icon
            icon="spinner"
            v-if="loading"
            class="animate-spin my-3 mx-auto"
          />
          <p v-if="isEmpty" class="py-3">
            no sure what to read? click
            <button
              class="underline text-lg"
              @click="$router.push('/suggestions')"
            >
              here
            </button>
            for some inspiration
          </p>
          <div v-if="splitLinesDone && !isEmpty && !loading">
            <p class="pt-2">you will use about {{ creditsUse }} credits</p>
            <p class="pt-1" v-if="!enoughCredits">not enough credits!</p>
          </div>

          <simple-button
            v-if="!splitLinesDone && !isEmpty && !loading"
            class="mt-3"
            @click="fixInput()"
            color="white"
            >split sentences
          </simple-button>
          <simple-button
            v-if="showGenerateButton"
            class="mt-3"
            @click="onGenerateButton()"
            color="white"
            >create bilingual text</simple-button
          >

          <div class="mt-4" v-if="showGenerateButton">
            <span class="text-white"
              >Would you like to share this text with others?</span
            >
            <div class="mt-2">
              <label class="inline-flex items-center">
                <input
                  type="radio"
                  class="form-radio text-black"
                  name="accountType"
                  :checked="isPublic"
                  @click="isPublic = true"
                />
                <span class="ml-2">yes</span>
              </label>
              <label class="inline-flex items-center ml-6">
                <input
                  type="radio"
                  class="form-radio text-black"
                  name="accountType"
                  :checked="!isPublic"
                  @click="isPublic = false"
                />
                <span class="ml-2">no</span>
              </label>
            </div>
            <p class="text-white text-sm mt-6">
              disclaimer: platform administration is not responsible for user
              shared content
            </p>
          </div>

          <p class="text-center py-3" v-if="!isSignedIn">
            need to
            <button class="underline" @click="$router.push('login')">
              sign in
            </button>
            using the app
          </p>

          <BuyCreditsButton v-if="!loading && !enoughCredits && isSignedIn" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./Navbar";
import BuyCreditsButton from "./BuyCreditsButton";

import bilingualHelper from "../bilingualText";
import requestHelper from "../request";
export default {
  components: { BuyCreditsButton, Navbar },
  metaInfo: {
    title: "Create New Text",
  },
  props: {},
  computed: {
    isEmpty: function () {
      return this.textLength == 0;
    },
    enoughCredits: function () {
      return this.creditsUse <= this.credits;
    },
    credits: function () {
      return this.$store.getters.credits;
    },
    textLength: function () {
      return this.textBeforeTranslation.length;
    },
    creditsUse: function () {
      return this.textBeforeTranslation.length;
    },
    cost: function () {
      return (this.creditsUse * this.oneCreditPrice).toFixed(4);
    },
    oneCreditPrice: function () {
      return 1 / 10000;
    },
    isSignedIn: function () {
      return this.$store.getters.isSignedIn;
    },
    showGenerateButton: function () {
      return (
        this.splitLinesDone &&
        this.isSignedIn &&
        this.enoughCredits &&
        this.textLength > 0 &&
        !this.loading
      );
    },
  },
  data: function () {
    return {
      isPublic: false, //is sharing this text to other users? default needs to be false
      languages: ["en", "ko", "ja", "ru", "es", "pl", "fr", "zh"],
      targetLanguage: "en",
      textBeforeTranslation: "",
      splitLinesDone: false,
      loading: false, //if waiting for translation set this to true
    };
  },
  watch: {},
  mounted: function () {},
  methods: {
    onGenerateButton: function () {
      if (!this.isSignedIn) return;
      this.loading = true;
      this.generateBilingualText();

      this.$gtag.event("created-text", { method: "Google" });
    },
    generateBilingualText: async function () {
      this.fixInput();
      this.$store.commit("setTextBefore", this.textBeforeTranslation);

      await this.translate();
      await this.makeParallelText();
      this.loading = false;
    },
    fixInput: function () {
      this.oneSentenceOneLine();
      this.removeNewLines();
      this.splitLinesDone = true;
    },
    //takes blob of text and separates each sentence and put it in new line
    oneSentenceOneLine: function () {
      this.textBeforeTranslation = bilingualHelper.splitSentences(
        this.textBeforeTranslation
      );
    },
    removeNewLines: function () {},
    translate: async function () {
      let request = {
        isPublic: this.isPublic,
        input: this.$store.state.textBeforeTranslation,
        targetLanguage: this.$store.state.userNativeLanguage,
        sessionId: this.$store.state.sessionId,
      };
      let responseBody = await requestHelper.send("translate", request);

      if (responseBody.error) {
        console.log("translation failed!");
      } else {
        //let translation = responseBody.translation;
        //this.$store.commit("setTextAfter", translation);
        this.$store.commit("setUser", responseBody.user);
        this.$router.push(`/text/${responseBody.textId}`);
      }
    },
    //need to refresh token every once in a while
    refreshToken: async function () {
      const googleUser = await this.$gAuth.signIn();
      let token = googleUser.getAuthResponse().id_token;
      let email = googleUser.getBasicProfile().getEmail();
      this.$store.commit("signin", {
        token: token,
        email: email,
      });
    },
    makeParallelText: async function () {
      let before = this.$store.state.textBeforeTranslation;
      let after = this.$store.state.textAfterTranslation;
      let result = bilingualHelper.create(before, after);

      console.log(result);
      this.$store.commit("setBilingualText", result);
    },
  },
};
</script>
