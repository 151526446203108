<template>
  <div class="bg-white text-black">
    <navbar />
    <div class="py-4 px-8"></div>
    <div class="p-6 lg:p-10 text-justify">
      <p class="text-xl p-4 text-primary text-center">
        What kind of text is the best for creating bilingual texts?
      </p>
      <p class="py-8 md:w-1/2 mx-auto">
        Usually online novels are good start.<br />
        Online novels are usually written by aspiring writters so they often use
        less complex language.<br />
        Most online novels focus on dialogues and sentences are not that long.
      </p>
      <p class="p-4 text-xl text-primary text-center">
        Having problems with copying text from websites?
      </p>
      <p class="p-4 text-center">
        Try using
        <a
          class="underline"
          target="_blank"
          href="https://chrome.google.com/webstore/detail/enable-copy/lmnganadkecefnhncokdlaohlkneihio/overview"
          >this browser extension</a
        >
      </p>

      <p class="text-xl p-10 mt-3 text-primary text-center">
        Here are some links with reading materials:
      </p>
      <div
        v-for="suggestion in suggestions"
        :key="suggestion.language"
        class="text-center"
      >
        <p class="p-4 text-2xl pt-5 text-primary">{{ suggestion.language }}</p>
        <div v-for="website in suggestion.list" :key="website.title">
          <a class="underline" :href="website.url" target="_blank">{{
            website.title
          }}</a>
          <div v-if="website.exampleUrl" class="p-3 pl-4 mt-2">
            <a class="underline" :href="website.exampleUrl" target="_blank">
              example text
            </a>
          </div>
        </div>
      </div>
      <p class="text-xl text-primary pb-6 pt-10 text-center">Tutorial Video</p>
      <tutorial-video />
    </div>
  </div>
</template>
<script>
import Navbar from "./Navbar.vue";
import TutorialVideo from "./TutorialVideo.vue";
export default {
  metaInfo: {
    title: "Suggestions",
  },
  data: function () {
    return {
      suggestions: [
        {
          language: "Korean",
          list: [
            {
              url: "https://novel.naver.com/",
              title: "novel naver - online novels",
              exampleUrl:
                "https://novel.naver.com/webnovel/detail.nhn?novelId=765207&volumeNo=1",
            },
          ],
        },
        {
          language: "Japanese",
          list: [
            {
              url: "https://www.alphapolis.co.jp/novel",
              title: "alphapolis - online novels",
              exampleUrl:
                "https://www.alphapolis.co.jp/novel/403138955/53451420/episode/3780017",
            },
          ],
        },

        {
          language: "Spanish",
          list: [
            {
              url: "https://booknet.com/es/top/joven-adulto",
              title: "booknet es - free novels for young adults",
              exampleUrl:
                "https://booknet.com/es/reader/casada-con-una-mentira-b273170?c=2640987",
            },
          ],
        },

        {
          language: "German",
          list: [
            {
              url:
                "https://www.fanfiktion.de/Jugendliteratur/c/300000112/1/updatedate",
              title: "free young adult online novels",
              exampleUrl:
                "https://www.fanfiktion.de/s/5ef5c474000c293012665aaf/2/Scorpions",
            },
          ],
        },
        {
          language: "French",
          list: [
            {
              url: "https://www.fanfictions.fr/",
              title: "fanfiction in french",
              exampleUrl:
                "https://www.fanfictions.fr/fanfictions/harry-potter/13742_annastasia-et-l-ultime-affrontement/47173_le-complexe-d-anna/lire.html",
            },
          ],
        },
        //*/
      ],
    };
  },
  components: { Navbar, TutorialVideo },
};
</script>
<style scoped></style>
