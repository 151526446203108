import Vue from "vue";
import VueAllAuth from "vue-all-auth";
Vue.use(VueAllAuth, {
  /*
  google: {
    // keys for google
    client_id: "YOUR_GG_APP_ID.apps.googleusercontent.com",
    scope: "profile email",
  },
  */
  facebook: {
    // keys for fb
    appId: "430224641512672",
    cookie: true,
    xfbml: true,
    version: "v3.2",
  },
  /*
  twitter: {
    // keys for twitter
  },
  github: {
    // keys for github
  },
  */
});
Vue.allAuth()
  .facebook()
  .init();
