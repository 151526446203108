<template>
  <div>
    <navbar />
    <div class="bg-white text-center">
      <p class="text-2xl py-8">word list</p>
      <loading-icon v-if="loading" />
      <div v-else>
        <simple-button class="mb-8 mr-3" @click="editMode = !editMode">
          edit
        </simple-button>
        <simple-button
          class="mb-8"
          color="primary"
          @click="$router.push('practice')"
        >
          practice
        </simple-button>
        <div v-if="isEmpty">
          <p>Your word list in empty.</p>
          <p class="text-2xl py-4">How to add words to this list?</p>
          <p>1. open any bilingual text from library or public library</p>
          <p>2. click on word to see translation</p>
          <p>3. click "add word" button</p>
        </div>
        <div v-for="(word, i) in wordList" :key="i" class="py-1">
          <p class="inline">{{ word[0] }} - {{ word[1] }}</p>
          <p
            v-if="editMode"
            class="ml-4 inline text-red-600"
            @click="deleteWord(word[0])"
          >
            delete
          </p>
        </div>
      </div>
        <div class="py-8" />
    </div>
  </div>
</template>

<script>
import request from "../request";
import SimpleButton from "./SimpleButton.vue";
export default {
  components: { SimpleButton },
  data: function () {
    return {
      words: undefined,
      loading: true,
      editMode: false,
      forceUpdateWords: false, //hotfix: words were not updating inside computed function
    };
  },
  methods: {
    deleteWord: async function (word) {
      this.forceUpdateWords = false;
      console.log("deleting " + word);
      //delete on front end
      delete this.words[word];
      //hotfix:force computed update
      this.forceUpdateWords = true;
      //then delet on back end
      await request.send("delete-word", {
        sessionId: this.$store.state.sessionId,
        word: word,
      });
    },
  },
  computed: {
    isEmpty: function () {
      if (this.loading) return false;
      return this.wordList.length == 0;
    },
    wordList: function () {
      if (this.words == undefined) return [];
      if (this.forceUpdateWords) {
        console.log("force update");
      }
      let arr = [];
      let words = this.words;
      for (let key in words) {
        arr.push([key, words[key]]);
      }
      return arr;
    },
  },
  mounted: async function () {
    this.loading = true;
    let response = await request.send("word-list", {
      sessionId: this.$store.state.sessionId,
    });
    this.loading = false;
    if (!response.error) {
      this.words = response.words;
    }
  },
};
</script>