<template>
  <div
    :class="`${classes} w-full bg-primary py-4 px-6 lg:px-10 text-white flex flex-col lg:flex-row space-x-0 lg:space-x-4 space-y-6 lg:space-y-0 justify-between text-center z-30 shadow`"
  >
    <button
      v-if="!show && !isWide"
      @click="show = true"
      class="lg:invisible lg:w-0 flex justify-end"
    >
      <font-awesome-icon icon="bars" size="lg" />
    </button>
    <button
      v-if="show"
      class="transform duration-100 hover:scale-110"
      @click="$router.push('/')"
    >
      home
    </button>
    <button
      v-if="show"
      class="transform duration-100 hover:scale-110"
      @click="$router.push('/input')"
    >
      create new text
    </button>

    <button
      v-if="show && isSignedIn"
      class="transform duration-100 hover:scale-110"
      @click="$router.push('/my-texts')"
    >
      my library
    </button>
    <button
      v-if="show"
      class="transform duration-100 hover:scale-110"
      @click="$router.push('/free')"
    >
      free bilingual texts
    </button>

    <button
      v-if="show && isSignedIn"
      class="transform duration-100 hover:scale-110"
      @click="$router.push('/words')"
    >
      word list
    </button>

    <button
      class="flex justify-center transform duration-100 hover:scale-110"
      @click="onCreditsButton()"
      v-if="show && isSignedIn"
    >
      <p class="flex-shrink">credits: {{ credits }}</p>
      <font-awesome-icon
        icon="shopping-cart"
        class="flex-shrink ml-3 mr-1 mt-1"
      />
    </button>
    <UserEmail
      class="transform duration-100 hover:scale-110"
      v-if="isSignedIn && show"
    />

    <button
      v-else-if="show && !isSignedIn"
      class="transform duration-100 hover:scale-110"
      @click="$router.push('/login')"
    >
      sign in
    </button>

    <button v-if="show && !isWide" class="flex-1 lg:px-4" @click="show = false">
      hide menu
    </button>
  </div>
</template>

<script>
import UserEmail from "./UserEmail";
export default {
  components: { UserEmail },
  data: function () {
    return {
      show: false,
    };
  },
  computed: {
    classes: function () {
      let result = "";
      if (this.isFixed) result += "fixed ";
      return result;
    },
    isWide: function () {
      return window.innerWidth > 768;
    },
    credits: function () {
      return this.$store.getters.credits;
    },
    isSignedIn: function () {
      return this.$store.getters.isSignedIn;
    },
  },
  methods: {
    onCreditsButton: function () {
      this.$router.push("/shop");
    },
  },
  mounted: function () {
    this.show = this.isWide;
    console.log("fixed: " + this.isFixed);
  },
  props: {
    text: String,
    isFixed: Boolean,
  },
};
</script>
