<template>
  <div class="bg-primary text-white">
    <navbar />
    <div class="p-4 md:w-2/3 lg:w-1/2 mx-auto flex flex-col mb-20" v-if="!sent">
      <p class="text-2xl text-center my-4">Do you have any feedback?</p>
      <div class="flex flex-col">
        <p class="py-4">your email (optional)</p>
        <input
          type="text"
          v-model="name"
          placeholder="john@gmail.com"
          class="rounded bg-white text-black p-2 px-4"
        />
        <p class="py-4">your feedback</p>
        <textarea
          class="bg-white text-black rounded p-4"
          rows="10"
          v-model="content"
          placeholder="What do you think of this app?"
        ></textarea>
      </div>
      <simple-button
        @click="send()"
        color="white"
        class="my-6"
        v-if="content != ''"
        >send feedback</simple-button
      >
    </div>
    <div v-else>
      <p class="my-20 text-2xl text-center">thank you!</p>
    </div>
  </div>
</template>

<script>
const request = require("../request");
export default {
  data: () => {
    return {
      content: "",
      userName: "",
      sent: false,
    };
  },
  methods: {
    send: async function () {
      await request.send("feedback", {
        content: this.content,
        name: this.userName,
      });
      this.sent = true;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
</style>