<template>
  <div>
    <navbar />
    <p class="text-white py-10 text-2xl text-center">tutorial video</p>
    <div class="m-4 md:w-2/3 md:mx-auto mb-20">
      <tutorial-video />
    </div>
  </div>
</template>

<script>
import TutorialVideo from "./TutorialVideo.vue";
export default {
  components: { TutorialVideo },
};
</script>

<style>
</style>