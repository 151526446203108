<template>
  <div class="flex flex-col lg:flex-row py-8 lg:min-h-screen">
    <div class="flex-1 flex flex-col justify-center text-center text-white">
      <p class="my-10 text-2xl mx-8">What language do you want to learn?</p>
    </div>
    <div
      class="flex-1 flex flex-col justify-center md:w-1/2 space-y-2 px-4 md:mx-auto my-10"
    >
      <simple-button
        class="lg:w-1/2 mx-4"
        color="white"
        v-for="language in languages"
        :key="language.code"
        @click="$router.push(`/free/${language.url}`)"
      >
        <div class="flex justify-around">
          <flag
            :code="language.countryCode"
            class="w-8 inline flex-shrink shadow"
          />
          <p class="inline flex-1">{{ language.languageName }}</p>
        </div>
      </simple-button>
      <simple-button
        class="lg:w-1/2 mx-4 mt-4"
        color="white"
        @click="$router.push(`/free/all`)"
      >
        <div class="flex justify-around">
          <flag code="" class="w-8 inline flex-shrink" />
          <p class="inline flex-1">Other</p>
        </div>
      </simple-button>
    </div>
  </div>
</template>
<script>
const ISO6391 = require("iso-639-1");
export default {
  components: {},
  data: () => {
    return {
      languageCodes: ["es", "de", "fr", "ko", "ja", "zh"],
    };
  },
  computed: {
    languages() {
      return this.languageCodes.map((code) => {
        let name = ISO6391.default.getName(code);
        return {
          countryCode: this.languageCodeToCountryCode(code),
          code: code,
          languageName: name,
          url: name.toLowerCase(),
        };
      });
    },
  },
  methods: {
    languageCodeToCountryCode(languageCode) {
      switch (languageCode) {
        case "ko":
          return "kr";
        case "ja":
          return "jp";
        case "zh":
          return "cn";
        default:
          return languageCode;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>