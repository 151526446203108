function create(before, after) {

	let sentences1 = before.split("\n");
	let sentences2 = after.split("\n");
	let result = "";
	let lineCount = sentences1.length;
	for (let index = 0; index < lineCount; index++) {
		try {
			const line1 = sentences1[index];
			const line2 = sentences2[index];
			if (line2 != undefined) {
				result += line2;
				result += "\n";
			}
			if (line1 != undefined) {
				result += line1;
				result += "\n\n";
			}
		} catch {
			console.log("something went wrong when creating parallel text");
		}
	}
	result = result.slice(0, -2);
	return result;
}

function splitSentences(input) {
	let content = input;
	content = content.replaceAll(".", ".\n"); //add new line after new sentence
	content = content.replaceAll("?", "?\n"); //add new line after new sentence
	content = content.replaceAll("!", "!\n"); //add new line after new sentence
	content = content.replaceAll(":", ":\n"); //add new line after new sentence
	content = content.replaceAll(";", ";\n"); //add new line after new sentence
	content = content.replaceAll("。", "。\n"); //add new line after new sentence
	content = content.replaceAll("\n ", "\n"); //remove spaces from front of sentence
	for (let index = 0; index < 5; index++) {
		content = content.replaceAll("\n.\n", ".\n\n"); //single dot on line => move to previous sentence
		content = content.replaceAll('\n"\n', '"\n\n'); //single quotation on line => move to previous sentence
		content = content.replaceAll("\n”\n", "”\n\n"); //single quotation on line => move to previous sentence
		content = content.replaceAll("\n'\n", "'\n\n"); //single quotation on line => move to previous sentence
		content = content.replaceAll("\n’\n", "’\n\n"); //single quotation on line => move to previous sentence

		content = content.replaceAll("\n]\n", "]\n\n"); //single parentheses on line => move to previous sentence
	}


	for (let index = 0; index < 4; index++) {
		content = content.replaceAll("\n\n", "\n"); //remove unnecessary new lines
	}
	if (content[0] == "\n") content = content.substring(1);
	if (content[content.length - 1] == "\n") content = content.slice(0, -1);
	return content;
}

function toHtml(input) {
	let text = "";
	let lines = input.split("\n");
	for (let index = 0; index < lines.length; index++) {
		const element = lines[index];
		let changeColor = index % 3 == 1;
		if (changeColor) {
			text += `<p class="p-2 rounded bg-primary-200 text-primary-700">${element}</p>`;
		} else {
			text += `<p class="p-2 text-gray-700">${element}</p>`;
		}
	}
	return text;
}

function toHtmlWithTranslation(input) {
	let text = "";
	let lines = input.split("\n");
	for (let index = 0; index < lines.length; index++) {
		const element = lines[index];
		let changeColor = index % 3 == 1;
		if (changeColor) {
			//this is text in foreign language
			let words = element.split(" ");
			let line = "";
			words.forEach(word => {
				let escaped = word.replaceAll("'", "\\'");
				line += `<button class="inline" @click="translateWord('${escaped}')">${word}</button> `
			});
			text += `<div class="p-2 rounded bg-primary-200 text-primary-700">${line}</div>`;
		} else {
			text += `<p class="p-2 text-gray-700">${element}</p>`;
		}
	}
	return text;
}
module.exports.create = create;
module.exports.toHtml = toHtml;
module.exports.toHtmlWithTranslation = toHtmlWithTranslation;
module.exports.splitSentences = splitSentences;