<template>
  <object
    class=""
    :data="`https://lipis.github.io/flag-icon-css/flags/4x3/${code}.svg`"
    type="image/svg+xml"
  ></object>
</template>
<script>
export default {
  props: {
    code: String,
  },
};
</script>