<template>
  <button v-if="this.$store.getters.isSignedIn" class="" @click="signout()">
    logout<!--{{ $store.state.email }}-->
  </button>
</template>

<script>
export default {
  props: {},
  data: function () {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    signout: async function () {
      await this.$gAuth.signOut();
      this.$store.commit("signout");
      console.log("push login");
      this.$router.push("/login");
    },
  },
  mounted: function () {},
};
</script>
