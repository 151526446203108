<template>
  <div class="flex flex-col bg-primary-100">
    <div
      class="flex-1 flex bg-primary text-white text-left flex-col lg:flex-row"
    >
      <div class="flex-1 z-20">
        <div
          class="flex flex-col items-center h-full justify-center p-4 xl:pt-20 text-center"
        >
          <p class="flex-shrink text-7xl special-font pt-4">bearlang</p>
          <p class="flex-shrink my-5 text-3xl special-font">
            learn language by reading
          </p>
          <p class="">create personalized bilingual texts</p>
          <girl-reading-indigo
            class="flex-shrink p-3 xl:w-1/2 lg:w-2/3 md:w-1/2 z-20 flip-horizontal"
          />
          <!--<object
            class="flex-shrink p-3 lg:w-2/3 md:w-1/2 z-20 flip-horizontal"
            data="girl-reading3.svg"
            type="image/svg+xml"
          ></object>-->
          <div class="flex-shrink z-20"></div>
        </div>
      </div>
      <div class="flex-1 p-8 pt-14">
        <div class="h-full flex flex-col justify-center">
          <p class="m-2 mb-4">example bilingual text:</p>
          <div
            class="p-6 bg-white text-black rounded-lg text-sm z-20 border border-primary"
          >
            <div v-html="previewText"></div>
          </div>
          <div class="z-20 text-center flex mt-2 space-x-2">
            <simple-button @click="onStart()" class="flex-1" color="white"
              >sign in to use app</simple-button
            >
            <!--<p class="mt-2">or</p>-->
            <simple-button
              @click="$router.push('/free')"
              class="flex-1"
              color="white"
              >read free samples</simple-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="transform -translate-y-1/2 sm:-translate-y-0 bg-primary-100 z-10 h-0"
    >
      <Wave
        id="wave"
        class="flex-shrink transform -translate-y-3/4 rotate-180"
      />
    </div>
    <div
      class="text-primary text-center z-10 transform translate-y-8 lg:translate-y-16 mb-4"
    >
      <div class="animate-bounce mx-auto">
        <p class="mx-auto text-lg">read more...</p>
        <!--arrow down to show that you can scroll-->
        <font-awesome-icon
          icon="angle-down"
          class="text-primary mt-0 mx-auto transform scale-150 translate-y-4"
        />
      </div>
    </div>

    <div
      class="bg-primary-100 flex flex-col text-left transform lg:-translate-y-10"
    >
      <div class="p-4 md:p-8 lg:p-0 lg:py-10 w-full lg:w-2/3 mx-auto">
        <how-it-works class="mt-20 lg:mt-40" />
        <how-it-works-2 class="mt-20 lg:mt-40" />
        <how-it-works-3 class="mt-20 lg:mt-40" />
        <div class="flex flex-col flex-shrink mt-20 lg:mt-40">
          <div class="flex-1"></div>
          <p
            class="flex-shrink lg:mt-auto m-auto text-center text-2xl py-8 mb-10"
          >
            Tutorial
          </p>
          <div class="flex-1 flex mb-8">
            <tutorial-video />
          </div>
        </div>
        <about class="mt-20 lg:mt-40" />

        <div class="mt-10 lg:mt-40 lg:mx-auto">
          <p class="text-2xl py-10 text-center font-bold">Benefits</p>
          <p class="text-center mb-20">
            What are benefits or reading bilingual text?
          </p>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-14 mt-6 text-center">
            <div v-for="benefit in benefitsList" :key="benefit.title">
              <font-awesome-icon
                :class="`transform scale-100 mb-4 text-${benefit.color}-800`"
                :icon="benefit.icon"
              />
              <p class="p-2 mb-2 font-bold">{{ benefit.title }}</p>
              <p class="text-justify p-4">
                {{ benefit.text }}
              </p>
            </div>
          </div>
        </div>
        <p class="mt-20 lg:mt-40 text-center text-2xl">Try now!</p>
        <div class="z-20 text-center flex mt-20 space-x-2">
          <simple-button @click="onStart()" class="flex-1" color="primary"
            >sign in to use app</simple-button
          >
          <!--<p class="mt-2">or</p>-->
          <simple-button
            @click="$router.push('/free')"
            class="flex-1"
            color="white"
            >read free samples</simple-button
          >
        </div>
        <!--
        <p class="text-2xl special-font py-6 text-center">
          how to read bilingual text?
        </p>
        <div class="text-center pb-8">
          <p class="p-3 font-bold">step 1.</p>
          <p>read sentence in the language you know</p>
          <p class="p-3 font-bold">step 2.</p>
          <p>read the same sentence in the language you want to learn</p>

          <p class="p-3 font-bold">step 3.</p>
          <p>repeat this process for each sentence.</p>
        </div>
        -->
      </div>
    </div>

    <div class="text-center underline p-3 mt-40"></div>
  </div>
</template>
<script>
import Wave from "../assets/wave.svg";
import GirlReadingIndigo from "../svg-components/GirlReadingIndigo";

import TutorialVideo from "./TutorialVideo.vue";
const bilingualHelper = require("../bilingualText");
import HowItWorks from "./HowWorks";
import HowItWorks2 from "./HowWorks2.vue";
import HowItWorks3 from "./HowWorks3.vue";
import About from "./About";
export default {
  components: {
    Wave,
    TutorialVideo,
    About,
    HowItWorks,
    HowItWorks2,
    HowItWorks3,
    GirlReadingIndigo,
  },
  data: function () {
    return {
      benefitsList: [
        {
          icon: "paragraph",
          color: "green",
          title: "Learn Vocabulary",
          text: `You will learn new vocabulary without using dictionary. After
                seeing a word many times in context, you achieve greater
                understaning how it fits in the sentence.`,
        },

        {
          icon: "glasses",
          color: "red",
          title: "Learn Grammar",
          text: `Reading text is the best way to learn a grammar. Seeing grammar
                patterns many times will help you remember them more easily.
                Learn grammar that is frequently used by native speakers.`,
        },
        {
          icon: "university",
          color: "blue",
          title: "Sound Like Native",
          text: `Reading native materials will teach you how native speakers
                express themselves. Don't just remember grammar points, see how
                native speakers use them.`,
        },
        {
          icon: "sun",
          color: "pink",
          title: "Stay Motivated",
          text: `Are textbooks fun for you? I guess no. Read
                a text that interests you, like a story about magic!`,
        },
      ],
      readMore: false,
      previewTextRaw: `But he passed, his cold gaze fixed ahead.
Pero pasó, con su mirada fría fija adelante.

"It seems that he doesn't remember you more," said a friend, who had followed the incident with him.
—Parece que no se acuerda más de ti—le dijo un amigo, que a su lado había seguido el incidente.

-Not much!
—¡No mucho!` /*

He smiled.
—se sonrió él.`,
      /*`10 years ago.
10년 전.

This world has become a game.
이 세계는 게임이 되었다.

Reality has become a game.
현실이 게임이 되어버렸다.`,*/,
    };
  },
  computed: {
    previewText: function () {
      return bilingualHelper.toHtml(this.previewTextRaw);
    },
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
  methods: {
    onStart: function () {
      if (this.$store.getters.isSignedIn) {
        this.$router.push("/input");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>
<style scoped>
#wave {
  /*hotfix for slim white line*/
  background: rgb(114, 109, 168);
  background: linear-gradient(
    0deg,
    rgba(114, 109, 168, 1) 2%,
    rgba(251, 251, 252, 1) 4%
  );
}
</style>
