import InputText from "./components/InputText";
import Home from "./components/Home";
import Login from "./components/Login";
import PaymentSuccess from "./components/PaymentSuccess";
import PaymentCancel from "./components/PaymentCancel";
import Shop from "./components/Shop";
import Sample from "./components/Sample";
/*
import SampleList from "./components/SampleList";
import SampleListForLanguage from "./components/SampleListForLanguage"
*/
import Suggestions from "./components/Suggestions";
import MyTexts from "./components/MyTexts";
import Text from "./components/Text";
import PublicTexts from "./components/PublicTexts";
import TermsOfService from "./components/TermsOfService";
import WordList from "./components/WordList"
import Practice from "./components/Practice";
import SelectLanguage from "./components/SelectLanguage"
import Feedback from "./components/Feedback"
import Tutorial from "./components/Tutorial"

const routes = [{
		name: "text input",
		path: "/input",
		component: InputText
	},
	{
		name: "home",
		path: "/",
		component: Home
	},
	{
		name: "login",
		path: "/login",
		component: Login
	},
	{
		name: "payment success",
		path: "/paymentsuccess",
		component: PaymentSuccess,
	},
	{
		name: "payment cancel",
		path: "/paymentcancel",
		component: PaymentCancel
	},
	{
		name: "shop",
		path: "/shop",
		component: Shop
	},

	{
		name: "free sample",
		path: "/free/:languageName/:ebookName",
		component: Sample
	},
	/*
	{
		name: "free sample list",
		path: "/free",
		component: SampleList
	},
	{
		name: "free sample list",
		path: "/free/:languageName",
		component: SampleListForLanguage
	},
	*/
	{
		name: "suggestions",
		path: "/suggestions",
		component: Suggestions
	},
	{
		name: "my texts",
		path: "/my-texts",
		component: MyTexts
	},
	{
		name: "text",
		path: "/text/:textId",
		component: Text
	},
	{
		name: "free - select language",
		path: "/free",
		component: SelectLanguage
	},
	{
		name: "public texts",
		path: "/free/:filter",
		component: PublicTexts
	},
	{
		name: "terms of service",
		path: "/tos",
		component: TermsOfService
	},
	{
		name: "word list",
		path: "/words",
		component: WordList
	},
	{
		name: "practice",
		path: "/practice",
		component: Practice
	},
	{
		name: "select language",
		path: "/select-language",
		component: SelectLanguage
	},
	{
		path: "/feedback",
		component: Feedback
	},
	{
		path: "/tutorial",
		component: Tutorial
	}
];
import VueRouter from 'vue-router'
export const router = new VueRouter({
	mode: 'history',
	routes,
});