<template>
  <div class="bg-primary text-black">
    <navbar />
    <div class="py-10"></div>
    <div v-if="loading" class="text-center pt-4 text-white pb-10">
      <loading-icon class="my-4 mx-auto" />
      <p>redirecting to checkout</p>
      <p class="my-4">wait...</p>
    </div>
    <div v-else class="flex items-center flex-col mb-20 md:px-5">
      <div
        class="flex lg:w-2/3 md:w-full w-3/4 space-y-2 md:space-y-0 md:space-x-2 text-center text-primary flex-col md:flex-row p-3 md:p-0"
      >
        <div
          v-for="pack in packs"
          :key="pack.price"
          class="flex flex-col justify-between flex-1 p-6 rounded text-xl shadow-lg border-primary border price-font text-black bg-white text-left"
        >
          <p class="text-2xl text-primary p-4">{{ pack.name }}</p>
          <pre class="p-4">{{ pack.description }}</pre>
          <div class="text-7xl p-8 flex justify-left pl-4">
            <p class="text-base">$</p>
            <p>{{ pack.price }}</p>
          </div>
          <p class="pt-4 pb-8 px-4 price-font text-base mt-auto">
            {{ pack.amount }} credits
          </p>

          <simple-button @click="buyMoreCredits(pack.amount)" color="primary">
            buy
          </simple-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Navbar from "./Navbar.vue";

export default {
  metaInfo: {
    title: "Shop",
  },
  components: { Navbar },
  data: function () {
    return {
      packs: [
        {
          amount: 20000,
          price: 2,
          name: "starter pack",
          description: "don't want to commit? \nstart small!",
        },
        {
          amount: 50000,
          price: 5,
          name: "standard pack",
          description: "hungry for more? \nget bigger pack",
        },

        {
          amount: 100000,
          price: 10,
          name: "expert pack",
          description: "plan to read a lot? \ngo with this!",
        },
      ],
      loading: false,
    };
  },
  mounted: function () {},

  methods: {
    buyMoreCredits: async function (amount) {
      this.$gtag.event(`buy-button`, { method: "Google" });
      this.loading = true;
      let isDebug = this.$store.getters.isDebug;
      // Create an instance of the Stripe object with your publishable API key
      let request = {
        amount: amount,
        sessionId: this.$store.state.sessionId,
        isDebug: isDebug,
      };
      let stripeKey = "";
      if (isDebug) {
        console.log("is debug");
        stripeKey =
          "pk_test_51I8KIDCmT1kH8oUCeKX7iy7wpyHlIUzmwdlPq6VzDK0DIZfzyZjnnSzCjfKi9Qy4EtA8ptUMwE0Ma4TzOmBWQoSc00cez4dVf2";
      } else {
        stripeKey =
          "pk_live_51I8KIDCmT1kH8oUCTWAbQaHaJchGRKjBXKuL0WB2TlYWH7f57sq61LwQ286gDmp4CZpDGk1xH8VK5q9MUWNPnVMT00HdleHswC";
      }

      const stripe = await loadStripe(stripeKey);

      console.log(JSON.stringify(request));

      fetch("https://us-central1-bilinguo.cloudfunctions.net/payment", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      })
        .then(function (response) {
          return response.json();
        })

        .then(function (session) {
          if (session != undefined && session.id != undefined) {
            return stripe.redirectToCheckout({ sessionId: session.id });
          } else {
            console.log("something went wrong");
          }
        })

        .then(function (result) {
          // If redirectToCheckout fails due to a browser or network

          // error, you should display the localized error message to your

          // customer using error.message.

          if (result.error) {
            alert(result.error.message);
          }
        })

        .catch(function (error) {
          console.error("Error:", error);
        });
    },
  },
};
</script>

<style lang="postcss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");
.price-font {
  font-family: "Noto Sans JP", sans-serif;
}
</style>