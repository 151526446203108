<template>
  <div class="mx-auto lg:m-auto border-2 rounded p-4 w-full">
    <video-player class="video-player-box" ref="videoPlayer" :options="video">
    </video-player>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      video: {
        sources: [
          {
            src: "./howto.mp4",
            type: "video/mp4",
          },
        ],
        muted: true,
        fluid: true,
      },
    };
  },
};
</script>