
<template>
  <div>
    <p class="text-2xl text-center mb-10 md:mb-20">Translate Words</p>
    <div
      class="flex-shrink flex flex-col lg:flex-row lg:space-x-10 items-center"
    >
      <div class="flex-1">
        <p class="text-center p-4">click on word...</p>

        <div class="shadow rounded p-5 bg-white" v-html="previewText"></div>
      </div>
      <div class="flex-shrink lg:pt-10">
        <font-awesome-icon
          icon="arrow-right"
          class="text-primary transform scale-150 rotate-90 lg:rotate-0 mt-8 lg:mt-0 mb-2"
        />
      </div>

      <div class="flex-1">
        <p class="text-center p-4">... to see translation</p>
        <div class="shadow rounded p-5 text-center bg-white">
          <div class="">
            <p>adelante</p>
            <hr class="my-2" />
            <p>ahead</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bilingualHelper from "../bilingualText";
export default {
  data: function () {
    return {
      howToBefore: `10년 전. 이 세계는 게임이 되었다. 현실이 게임이 되어버렸다.`,
      previewTextRaw: `But he passed, his cold gaze fixed ahead.
Pero pasó, con su mirada fría fija adelante.`,
    };
  },
  computed: {
    previewText: function () {
      let result = bilingualHelper.toHtmlWithTranslation(this.previewTextRaw);
      result = result.replace(
        ">adelante.<",
        "><p class='inline bg-black text-white border rounded p-1 px-3 transform '>adelante.</p><"
      );
      return result;
    },
  },
};
</script>