import Vue from "vue";
import App from "./App.vue";
import "./index.css";
import Vuex from "vuex";
import VueRouter from "vue-router";
import Cookies from "js-cookie";
Vue.use(Vuex);
//store persistent data
const store = new Vuex.Store({
  state: {
    page: "home", //use this to decide which screen show now
    textBeforeTranslation: "",
    textAfterTranslation: "",
    bilingualText: "",
    userNativeLanguage: "en",
    wantToLearnLanguage: "fr",
    needUpdateCredits: false,
    user: undefined, // info about credits stored in user.credits
    email: Cookies.get("email"),
    sessionId: Cookies.get("sessionId"),
    token: "",
    tokenType: "",
    loginProgress: {
      text: "no progress", //show this when logging in
      percent: 0, //100 is 100%
    },
  },
  getters: {
    isDebug: () => {
      return location.hostname === "localhost";
    },
    isSignedIn: (state) => {
      if (state.sessionId == undefined) return false;
      return state.sessionId != "";
    },
    credits: (state) => {
      if (state.user == undefined) return 0;
      if (state.user.credits == undefined) return 0;
      return state.user.credits;
    },
    shortResult: (state) => {
      return state.bilingualText
        .split("\n")
        .slice(0, 6)
        .join("\n");
    },
  },
  mutations: {
    goto(state, page) {
      state.page = page;
    },
    resetText(state) {
      state.textBeforeTranslation = "";
      state.textAfterTranslation = "";
    },
    setTextBefore(state, text) {
      state.textBeforeTranslation = text;
    },
    setTextAfter(state, text) {
      state.textAfterTranslation = text;
    },
    signout(state) {
      state.email = "";
      state.sessionId = "";

      Cookies.remove("email");
      Cookies.remove("sessionId");
    },
    //type: google, facebook
    setToken(state, info) {
      state.token = info.token;
      state.tokenType = info.type;
    },
    signin(state, info) {
      console.log(info);
      state.token = info.token;

      //Cookies.set("sessionId", info.sessionId, { expires: 30 });
    },
    setBilingualText(state, text) {
      state.bilingualText = text;
    },
    setUser(state, info) {
      state.user = info;
      state.email = info.email;
      Cookies.set("email", info.email, {
        expires: 30
      });
    },
    setSessionId(state, sessionId) {
      state.sessionId = sessionId;
      Cookies.set("sessionId", sessionId, {
        expires: 30
      });
    },
    setLoginProgress(state, info) {
      state.loginProgress = info;
    },
  },
});

const router = require("./router").router;
console.log(router)
Vue.use(VueRouter);

import {
  library
} from "@fortawesome/fontawesome-svg-core";
import {
  faSpinner,
  faShoppingCart,
  faAngleDown,
  faBookOpen,
  faSun,
  faUniversity,
  faParagraph,
  faUsers,
  faGlasses,
  faArrowRight,
  faTimes,
  faBars
} from "@fortawesome/free-solid-svg-icons";
import {
  faGoogle,
  faFacebook
} from "@fortawesome/free-brands-svg-icons";
import {
  FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
library.add(
  faSpinner,
  faShoppingCart,
  faAngleDown,
  faBookOpen,
  faSun,
  faUniversity,
  faParagraph,
  faUsers,
  faGlasses,
  faArrowRight,
  faTimes,
  faGoogle,
  faFacebook, faBars
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

import VueGtag from "vue-gtag";

Vue.use(
  VueGtag, {
    appName: "bilinguo",
    config: {
      id: "G-T1W66TVCRG"
    },
    pageTrackerScreenviewEnabled: true,
  },
  router
);

Vue.config.productionTip = false;
//src/main.js
import GAuth from "vue-google-oauth2";
const gauthOption = {
  clientId: "888615669805-4vtg36c04rpj2s90e9frk8i18641pa90.apps.googleusercontent.com",
  scope: "email",
  //prompt: "select_account",
};
Vue.use(GAuth, gauthOption);

import "./oauth";

import VueVideoPlayer from "vue-video-player";

// require videojs style
import "video.js/dist/video-js.css";
// import 'vue-video-player/src/custom-theme.css'

Vue.use(
  VueVideoPlayer
  /* {
  options: global default options,
  events: global videojs events
} */
);
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

import Flag from "./components/Flag";
Vue.component("flag", Flag);
import LoadingIcon from "./components/LoadingIcon"
Vue.component("loading-icon", LoadingIcon)
import Popup from "./components/Popup";
Vue.component("popup", Popup)
import SimpleButton from "./components/SimpleButton"
Vue.component("simple-button", SimpleButton)

import Navbar from "./components/Navbar";
Vue.component("navbar", Navbar)


new Vue({
  render: (h) => h(App),
  store: store,
  router,
}).$mount("#app");