<template>
  <div>
    <navbar />
    <div class="flex text-white text-left flex-col lg:flex-row">
      <div class="flex-1 mb-12 lg:mb-20">
        <div class="h-full flex flex-col justify-center">
          <p class="text-4xl text-center mt-14">welcome</p>
          <girl-flying class="lg:w-1/3 md:w-1/3 mx-auto my-8" />

          <div class="mx-auto">
            <p class="my-2">sign in to:</p>
            <ul class="list-disc">
              <li>create bilingual text</li>
              <li>use on-click translation</li>
              <li>practice vocabulary with <br />flashcards</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="flex-1">
        <div class="w-3/4 mx-auto lg:w-1/2 flex flex-col justify-center h-full">
          <div v-if="loading" class="text-center mx-auto">
            <loading-icon class="my-4" />
            <!--<p class="my-2">{{ $store.state.loginProgress.percent }}%</p>-->
            <!--<p>wait... this may take a minute</p>-->

            <p class="text-sm mt-2">{{ $store.state.loginProgress.text }}</p>
          </div>

          <button
            v-else
            @click="signinWithGoogle()"
            class="my-3 p-2 shadow-md bg-white hover:text-gray text-primary items-center flex rounded"
          >
            <font-awesome-icon
              class="transform scale-125 m-2 flex-shrink ml-4"
              :icon="['fab', 'google']"
            />
            <p class="flex-1 pr-2">sign in with Google</p>
          </button>
          <button
            v-if="!loading"
            class="my-3 p-2 shadow-md bg-white hover:text-gray text-primary items-center flex rounded"
            @click="signinWithFacebook()"
          >
            <font-awesome-icon
              class="transform scale-125 m-2 flex-shrink ml-4"
              :icon="['fab', 'facebook']"
            />
            <p class="flex-1 pr-2">sign in with Facebook</p>
          </button>
          <div class="mx-auto my-10 text-center" v-if="!loading">
            <p>
              by signing in, <br />you accept
              <button class="underline" @click="$router.push('/tos')">
                Terms of Use
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./Navbar.vue";
import GirlFlying from "../svg-components/GirlFlying";
//import Vue from "vue";
export default {
  components: { Navbar, GirlFlying },
  data: function () {
    return {
      loading: false,
    };
  },
  metaInfo: {
    title: "Sign In",
  },
  methods: {
    signinWithFacebook: function () {
      this.loading = true;
      let FB = window.FB;
      let hook = this;
      FB.login(
        function (response) {
          if (response.authResponse) {
            console.log(JSON.stringify(response.authResponse));
            let token = response.authResponse.accessToken;
            hook.$store.commit("setToken", { token: token, type: "facebook" });
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        { scope: "email" }
      );
    },
    signinWithGoogle: async function () {
      this.loading = true;
      this.$store.commit("setLoginProgress", {
        text: "connecting to google account",
        percent: 20,
      });
      const googleUser = await this.$gAuth.signIn();
      let authResponse = googleUser.getAuthResponse();

      console.log(authResponse);
      let token = authResponse.id_token;
      let email = googleUser.getBasicProfile().getEmail();
      console.log(`token: ${token}`);
      this.$store.commit("signin", {
        token: token,
        email: email,
      });
    },
  },
  mounted: function () {},
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
