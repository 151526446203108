<template>
  <button
    class="p-8 shadow bg-white rounded my-4 transform duration-100 ease-out hover:-translate-y-1"
    @click="$router.push(`/text/${text.id}`)"
  >
    <div
      v-if="text.title"
      class="bg-white text-primary pb-2 mb-4 flex justify-around items-start"
    >
      <p class="flex-1 text-lg">{{ text.title }}</p>
      <div class="w-8 flex-shrink mx-3">
        <flag class="pointer-events-none border" :code="text.flag" />
      </div>
    </div>

    <p class="text-gray-500 mb-10 mt-8 underline text-left">
      this is a preview. click to see bilingual text
    </p>
    <pre class="text-left">{{ text.preview }}</pre>
  </button>
</template>
<script>
export default {
  props: {
    text: Object,
  },
};
</script>