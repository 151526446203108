<template>
  <div class="bg-white min-h-screen">
    <navbar />
    <div v-if="loading" class="flex justify-center py-8">
      <loading-icon />
    </div>
    <div v-else-if="showAnswer">
      <div v-if="isCorrect" class="text-center py-8 text-green-700">
        correct
      </div>
      <div v-else class="text-center text-2xl py-8 text-red-700">wrong</div>
      <div class="text-center text-2xl pb-8">{{ question }}</div>
      <div class="text-center text-2xl pb-8">{{ correctAnswer }}</div>
      <div class="flex justify-center pb-8">
        <simple-button @click="loadNewQuestion()">next question</simple-button>
      </div>
    </div>
    <div v-else class="flex flex-col px-5 py-8 lg:w-1/2 mx-auto space-y-4">
      <div class="text-center text-2xl">{{ question }}</div>
      <simple-button
        v-for="answer in answers"
        :key="answer[1]"
        @click="onAnswer(answer[1])"
        >{{ answer[1] }}</simple-button
      >
    </div>
  </div>
</template>
<script>
import request from "../request";
import SimpleButton from "./SimpleButton.vue";
export default {
  components: { SimpleButton },
  data: function () {
    return {
      loading: true,
      words: [],
      question: "",
      answers: [],
      correctAnswer: "",
      isCorrect: false,
      showAnswer: false,
    };
  },
  methods: {
    shuffle(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
    },
    shuffleWords() {
      console.log("shuffle words");
      this.shuffle(this.words);
    },
    getRandomWord() {
      this.shuffleWords();
      return this.words[0];
    },
    loadNewQuestion() {
      this.showAnswer = false;
      let answers = [];
      let word = this.getRandomWord();
      this.question = word[0];
      this.correctAnswer = word[1];
      //add real answer
      answers.push(word);
      //add fake answers
      while (answers.length < 4) {
        let random = this.getRandomWord();
        //if (answers.some((x) => x[0] == random[0])) {
        //duplicate answer
        //} else {
        answers.push(random);
        //}
      }
      this.shuffle(answers);
      this.answers = answers;
    },
    onAnswer: function (answer) {
      this.isCorrect = answer == this.correctAnswer;
      this.showAnswer = true;
    },
    loadWords: async function () {
      console.log("loadWords");
      this.loading = true;
      let response = await request.send("word-list", {
        sessionId: this.$store.state.sessionId,
      });
      let words = [];
      for (let key in response.words) {
        words.push([key, response.words[key]]);
      }
      this.words = words;

      this.loadNewQuestion();

      this.loading = false;
    },
  },
  mounted: async function () {
    console.log("mounted");
    await this.loadWords();
  },
};
</script>