
<template>
  <div>
    <p class="text-2xl text-center mb-10 md:mb-20">Practice with Flashcards</p>
    <div
      class="flex-shrink flex flex-col lg:flex-row lg:space-x-10 items-center"
    >
      <div class="flex-1">
        <p class="text-center p-4">practice words using flashcards</p>
      </div>
      <!--
      <div class="flex-shrink lg:pt-10">
        <font-awesome-icon
          icon="arrow-right"
          class="text-primary transform scale-150 rotate-90 lg:rotate-0 mt-8 lg:mt-0 mb-2"
        />
      </div>
      -->

      <div class="flex-1">
        <p class="text-center py-6 text-2xl">{{ question }}</p>
        <p class="text-gray-400 text-center pb-4">what does this word mean?</p>
        <div class="flex-col flex space-y-4">
          <simple-button v-for="answer in answers" :key="answer">{{
            answer
          }}</simple-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      question: "상처",
      answers: ["wound", "attack", "bear", "achievement"],
    };
  },
  computed: {},
};
</script>