<template>
  <div class="bg-primary-100 text-center">
    <navbar />
    <div class="pt-6">
      <div class="flex flex-col md:w-2/3 lg:w-1/2 mx-4 md:mx-auto">
        <!--<p class="text-2xl my-10">public library</p>-->
        <p v-if="languageFilter" class="mt-8 mb-6 text-2xl">
          {{ languageFilter }}
        </p>
        <p class="pb-6 pt-6">bilingual texts created by users</p>
        <p class="pb-6">click on preview to see full bilingual text</p>
        <div v-if="loading">
          <loading-icon class="my-3 mx-auto mb-8" />
        </div>

        <text-preview
          v-for="text in textsFiltered"
          :key="text.id"
          :text="text"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./Navbar.vue";
import TextPreview from "./TextPreview.vue";
const languageCodes = require("iso-639-1");
export default {
  components: { Navbar, TextPreview },
  data: function () {
    return {
      texts: undefined,
    };
  },
  metaInfo: {
    title: "Public Library",
  },
  computed: {
    loading: function () {
      return this.texts == undefined;
    },
    //filtering by language
    languageFilter: function () {
      let filter = this.$route.params.filter;
      return filter;
    },
    languageFilterCode: function () {
      return languageCodes.default.getCode(this.languageFilter);
    },
    hasFilter: function () {
      if (this.languageFilter == undefined) return false;
      return this.languageFilter != "all";
    },
    textsFiltered: function () {
      if (this.texts == undefined) return undefined;
      console.log(this.texts);
      //todo filter on server side
      if (this.hasFilter) {
        return this.texts.filter((x) => x.language == this.languageFilterCode);
      }
      return this.texts;
    },
  },
  methods: {
    sendRequest: async function (api, requestBody) {
      let baseUrl = "https://us-central1-bilinguo.cloudfunctions.net";

      let url = `${baseUrl}/${api}`;
      let response = await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(requestBody),
      });

      console.log(`sent ${JSON.stringify(requestBody)}`);
      let responseAsJson = await response.json();
      return responseAsJson;
    },
    languageCodeToCountryCode(code) {
      switch (code) {
        case "zh":
          return "cn";
        case "ja":
          return "jp";
        case "ko":
          return "kr";
      }
      return code;
    },
  },
  mounted: async function () {
    let response = await this.sendRequest("public-texts", {});
    this.texts = response.list;
    this.texts.forEach((element) => {
      element.flag = this.languageCodeToCountryCode(element.language);
    });
    this.texts.reverse();
  },
};
</script>