function asText(text) {

	console.log(text);
	let blob = new Blob([text], {
		type: "text/plain"
	});
	let anchor = document.createElement("a");

	let fileName = text.split("\n")[0];
	anchor.download = fileName + ".txt";
	anchor.href = window.URL.createObjectURL(blob);
	anchor.target = "_blank";
	anchor.style.display = "none";
	document.body.appendChild(anchor);
	anchor.click();
	document.body.removeChild(anchor);
}

function asHtml(bilingualText) {
	//experimental - position save
	/* eslint-disable */
	let positionSaver = `<script>
	//load scroll position
	function loadScrollPos(){
	  const container = document.documentElement;
	  container.scrollTop = localStorage["bilinguo-scroll-position"];
	}
	//saving scroll position
	function saveScrollPos(){
	  const container = document.documentElement;
	  localStorage["bilinguo-scroll-position"] = container.scrollTop;
	}
	//load last position
	loadScrollPos();
	//save position every second
	setInterval(() => {
		saveScrollPos();
	}, 1000);
	<\/script>
	`;
	let text = `<!DOCTYPE html><head>
	<meta charset="utf-8" />
	<meta name="viewport" content="width=device-width, initial-scale=1" /><style>
p {
padding: 0.5rem;
border-radius: 0.3rem;
margin: 0.2rem;
}
.secondLanguage {
background-color: #cfe0fc;
}
</style></head><body>`;
	let lines = bilingualText.split("\n");
	let fileName = lines[0];
	for (let index = 0; index < lines.length; index++) {
		const element = lines[index];
		let changeColor = index % 3 == 1;
		if (changeColor) {
			text += `<p class="secondLanguage">${element}</p>`;
		} else {
			text += `<p class="">${element}</p>`;
		}
	}
	text += positionSaver + "</body></html>";
	console.log(text);
	let blob = new Blob([text], {
		type: "text/plain"
	});
	let anchor = document.createElement("a");

	anchor.download = fileName + ".html";
	anchor.href = window.URL.createObjectURL(blob);
	anchor.target = "_blank";
	anchor.style.display = "none";
	document.body.appendChild(anchor);
	anchor.click();
	document.body.removeChild(anchor);
}

module.exports.asText = asText;
module.exports.asHtml = asHtml;