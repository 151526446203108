function load(key) {
	if (localStorage[key]) {
		const container = document.documentElement;
		let position = localStorage[key];
		container.scrollTop = position;
		console.log("loaded position : " + position);
	}
}

function save(key) {
	const container = document.documentElement;

	localStorage[key] = container.scrollTop;
	console.log("saved scroll position");
}

module.exports.load = load;
module.exports.save = save;