<template>
  <div>
    <navbar :isFixed="true" />
    <div class="p-4 bg-white pt-16">
      <div class="text-center pb-4">
        <p class="my-10 text-3xl">{{ metadata.title }}</p>
        <p v-if="metadata.author" class="pb-4 text-lg italic">
          {{ metadata.author }}
        </p>
        <p v-if="metadata.description" class="pb-4 text-gray-500">
          {{ metadata.description }}
        </p>
        <a v-if="metadata.url" :href="metadata.url" class="underline"
          >link to orginal text</a
        >
      </div>
      <div
        v-if="metadata.license == 'gutenberg'"
        class="my-2 p-4 bg-white rounded border"
      >
        {{ licensePrefix }}
      </div>
      <div
        v-if="bilingualHtml"
        class="bg-white p-4 rounded-lg"
        v-html="bilingualHtml"
      ></div>
      <div v-if="metadata.license && !hideLicense">
        <big-button
          class="my-3"
          text="hide license"
          @click="hideLicense = true"
        />
        <pre class="my-2 p-4 bg-white rounded text-center">
      {{ licenseSuffix }}
    </pre
        >
      </div>
    </div>
  </div>
</template>
<script>
const bilingualHelper = require("../bilingualText");
import Navbar from "./Navbar.vue";
import scrollPosition from "../scrollPosition";
export default {
  components: { Navbar },
  metaInfo: function () {
    return {
      title: this.metaTitle,
    };
  },
  data: function () {
    return {
      timer: undefined,
      hideLicense: false,
      bilingualText: "",
      metadata: {
        title: "",
        name: "",
        description: "",
        url: "",
        license: "",
      },

      licensePrefix: `This eBook is for the use of anyone anywhere at no cost and with
almost no restrictions whatsoever.  You may copy it, give it away or
re-use it under the terms of the Project Gutenberg License included
with this eBook or online at www.gutenberg.org`,
      licenseSuffix: ``,
    };
  },
  computed: {
    metaTitle: function () {
      if (this.metadata.title == "") return "FreeSample";
      return `${this.metadata.title} - Free Sample`;
    },
    scrollKey: function () {
      return `sample-scroll-${this.routePath}`;
    },
    routePath: function () {
      return this.$route.params.ebookName;
    },
    bilingualTextPath: function () {
      return `/free-samples/${this.routePath}.txt`;
    },
    bilingualMetaPath: function () {
      return `/free-samples/${this.routePath}.json`;
    },
    licensePath: function () {
      return `/free-samples/license/${this.metadata.license}.txt`;
    },
    bilingualHtml: function () {
      return bilingualHelper.toHtml(this.bilingualText);
    },
  },
  mounted: async function () {
    console.log("fetching");
    let bilingual = await fetch(this.bilingualTextPath);
    let metadata = await fetch(this.bilingualMetaPath);
    console.log("parsing");
    this.bilingualText = await bilingual.text();
    this.metadata = await metadata.json();

    console.log(`license path: ${this.licensePath}`);
    let license = await fetch(this.licensePath);
    console.log("parsing license");
    this.licenseSuffix = await license.text();
    console.log("license done");
    scrollPosition.load(this.scrollKey);
    this.timer = setInterval(() => {
      if (this.bilingualText) {
        scrollPosition.save(this.scrollKey);
      }
    }, 5000);
  },
  beforeDestroy: function () {
    clearInterval(this.timer);
  },
  methods: {},
};
</script>
