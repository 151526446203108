
<template>
  <div>
    <p class="text-2xl text-center mb-20">Translate Each Sentence</p>
    <div class="flex flex-col lg:flex-row lg:space-x-10 items-center">
      <div class="flex-1">
        <p class="text-center py-4">input text in foreign language...</p>
        <div class="shadow rounded p-8 bg-white">
          <p class="">
            {{ howToBefore }}
          </p>
        </div>
      </div>
      <div class="flex-shrink lg:pt-10">
        <font-awesome-icon
          icon="arrow-right"
          class="text-primary transform scale-150 rotate-90 lg:rotate-0 mt-8 lg:mt-0 mb-2"
        />
      </div>

      <div class="flex-1">
        <p class="text-center p-4">...to get translation for each sentence</p>
        <div class="shadow rounded p-5 bg-white" v-html="howToAfter"></div>
        <p class="text-center p-4">now you can read the text more easily!</p>
      </div>
    </div>
  </div>
</template>
<script>
import bilingualHelper from "../bilingualText";
export default {
  data: function () {
    return {
      howToBefore: `10년 전. 이 세계는 게임이 되었다. 현실이 게임이 되어버렸다.`,
      howToAfterRaw: `10 years ago.
10년 전.

This world has become a game.
이 세계는 게임이 되었다.

Reality has become a game.
현실이 게임이 되어버렸다.`,
    };
  },
  computed: {
    howToAfter: function () {
      return bilingualHelper.toHtml(this.howToAfterRaw);
    },
  },
};
</script>