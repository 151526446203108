<template>
  <div id="app" class="bg-primary min-h-screen md:h-full text-gray-800">
    <router-view />
    <my-footer />
  </div>
</template>

<script>
import requestHelper from "./request";
import MyFooter from "./components/Footer";
export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "bearlang",
    // all titles will be injected into this template
    titleTemplate:
      "%s | bearlang - learn language by reading bilingual parallel text",
  },
  components: { MyFooter },
  data: function () {
    return {};
  },
  computed: {
    isSignedIn: function () {
      return this.$store.getters.isSignedIn;
    },
    token: function () {
      return this.$store.state.token;
    },
    sessionId: function () {
      return this.$store.state.sessionId;
    },
  },
  watch: {
    isSignedIn: function () {
      //on signin
      if (this.isSignedIn) {
        if (this.$store.state.user) {
          this.$router.push("/input");
        } else {
          this.getUser();
        }
      }
    },

    token: function () {
      console.log("token change");
      //when logged into google but dont have session yet
      if (this.token) {
        console.log("has token");
        if (!this.sessionId) {
          console.log("sessionid empty");
          this.createSession();
        }
      }
    },
  },
  mounted() {
    if (this.isSignedIn) {
      this.getUser();
    }
  },

  methods: {
    registerNewUser: async function () {
      let tokenType = this.$store.state.tokenType;
      this.$store.commit("setLoginProgress", {
        text: "creating new user",
        percent: 80,
      });
      let request = {
        token: this.token,
      };
      let url = "register";
      if (tokenType == "facebook") {
        url = "signup-facebook";
      }
      let response = await requestHelper.send(url, request);
      if (response.error) {
        console.log(response);
      } else {
        this.$store.commit("setUser", response.user);
        this.$store.commit("setSessionId", response.sessionId);
      }
    },
    //move this to main app
    getUser: async function () {
      console.log("get user");
      let request = {
        sessionId: this.sessionId,
      };
      let url = "user";
      let response = await requestHelper.send(url, request);
      let user = response.user;

      console.log(user);

      if (response.error) {
        this.registerNewUser();
      } else {
        this.$store.commit("setUser", user);
      }
    },
    createSession: async function () {
      let tokenType = this.$store.state.tokenType;
      this.$store.commit("setLoginProgress", {
        text: "creating session",
        percent: 60,
      });
      let request = {
        token: this.token,
      };
      let api = "signin-google"; //add facebook and apple id login
      if (tokenType == "facebook") {
        api = "signin-facebook";
      }
      let response = await requestHelper.send(api, request);

      console.log(`response ${JSON.stringify(response)}`);
      if (response.error) {
        this.registerNewUser();
      } else {
        this.$store.commit("setSessionId", response.sessionId);
        this.$router.push("/input");
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Antic+Slab&family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
#app {
  /*font-family: "Antic Slab", serif;*/
  font-family: "Raleway", sans-serif;
}
/*remove ugly outline on chrome*/
button:focus {
  outline: none !important;
}
a:focus {
  outline: none !important;
}
textarea:focus {
  outline: none !important;
}

.special-font {
  font-family: "Fredoka One", cursive;
}

pre {
  white-space: pre-wrap;
  font-family: "Raleway", sans-serif !important;
}
.flip-horizontal {
  transform: scale(-1, 1);
}
.video-js .vjs-big-play-button {
  /*overriding default*/
  top: 50% !important;
  left: 50% !important;
  margin-left: -1em;
  margin-top: -1em;
  border-radius: 50% !important;
  width: 3rem !important;
  height: 3rem !important;
}
.form-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  border-radius: 100%;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #d1d5db;
  border-width: 1px;
}
.form-radio:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}
</style>
