<template>
  <button
    @click="$emit('click')"
    :class="`${bgColor} border border-primary p-3 rounded-lg transform transition duration-200 hover:text-primary-600 hover:-translate-y-1 `"
  >
    <p :class="`opacity-${textOpacity} ${textColor}`">
      <slot></slot>
    </p>
  </button>
</template>

<script>
export default {
  name: "Button",
  data: function () {
    return {};
  },
  computed: {
    bgColor: function () {
      return "bg-" + this.color;
    },
    textOpacity: function () {
      if (this.color == "primary") {
        return "100";
      }
      return "80";
    },
    textColor: function () {
      if (this.color == "primary") {
        return "text-white";
      }
      return "text-primary-800";
    },
  },
  methods: {},
  mounted: function () {},
  props: {
    color: String,
  },
};
</script>
